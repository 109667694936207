import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { formatNumber } from 'utilities/formatters';
import classNames from 'classnames';
import { useSocialElementsDisabled } from 'features/channel/channelHooks';

export const ChannelStats: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const followerLabel =
    channel && channel.followers === 1 ? 'Follower' : 'Followers';
  const socialElementsDisabled = useSocialElementsDisabled();

  if (!channel || socialElementsDisabled) {
    return null;
  }

  return (
    <ul className={classNames(styles['channel-stats'], 'list--reset')}>
      <li>
        <span className={styles['channel-stats__number']}>
          {formatNumber(channel.followers)}
        </span>
        {followerLabel}
      </li>
      <li>
        <span className={styles['channel-stats__number']}>
          {formatNumber(channel.listeners)}
        </span>
        Total Listens
      </li>
    </ul>
  );
};
