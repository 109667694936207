import { V3Network, V3NetworkChannel } from 'api/v3/types/network';
import { NetworkChannel } from 'features/channel/channelSlice';

export async function apiV3GetChannelNetwork(
  username: string
): Promise<V3Network> {
  const results = await fetch(
    `${process.env.REACT_APP_API_CDN_ENDPOINT}/v3/channels/${username}/network`
  );

  if (results.ok) {
    return await results.json();
  } else {
    throw results;
  }
}

export const mapApiV3NetworkChannel = (
  channel: V3NetworkChannel
): NetworkChannel => {
  return {
    channelId: channel.id,
    username: channel.attributes.username,
    themeColor: channel.attributes.theme_color,
    logoUrl: channel.attributes.logo_url,
    isLive: channel.attributes.live,
    url: channel.attributes.url
  };
};
