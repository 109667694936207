import React, { FunctionComponent, useState, useEffect } from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import paginationStyles from 'components/Pagination/styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Card } from 'components/Card';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchCollectionRecordings } from 'features/collections/collectionsSlice';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import {
  allPublicAndUnlistedCollectionRecordingsSelector,
  collectionRecordingsPageCount
} from 'features/collections/collectionsSelectors';
import { useCurrentCollection } from 'features/collections/collectionHooks';
import { useAppSelector } from 'hooks/redux';
import { EmptyCollectionRecordings } from './EmptyCollectionRecordings';
import { useOnceEffect } from 'hooks/useOnceEffect';

export interface PaginatedCollectionRecordingsProps {
  itemsPerPage: number;
}

export const PaginatedCollectionRecordings: FunctionComponent<
  PaginatedCollectionRecordingsProps
> = () => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const loading = useAppSelector(
    (state) => state.collections.collectionRecordingsLoading
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage: number = +(useParams().page || 1);
  const collection = useCurrentCollection();
  const recordings: RecordingModel[] = useSelector(
    allPublicAndUnlistedCollectionRecordingsSelector
  );
  const recordingsPageCount = useSelector(collectionRecordingsPageCount);

  const onPageChange = (event: { selected: number }) => {
    if (!collection) return;
    const scrollToOptions = { top: 0, left: 0, behavior: 'instant' };
    // https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1516836705
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);
    navigate(`../collections/${collection.id}/page/${event.selected + 1}`);
  };

  useEffect(
    function getRecordingSet() {
      setCurrentItems(recordings);
    },
    [recordings, setCurrentItems]
  );

  useOnceEffect(function fetchRecordingsOnLoadOrPageChange() {
    if (!collection) return;
    dispatch(
      fetchCollectionRecordings(subdomain, collection.id, currentPage, navigate)
    );
  }, collection);

  const setInitialPage = currentPage - 1;

  if (recordings.length === 0 && !loading) {
    return <EmptyCollectionRecordings />;
  }

  if (loading) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div className="container--wide tw-mt-8 standalone:tw-mt-0">
      {currentItems.length > 0 && (
        <ol className="list--reset tw-grid tw-grid-cols-12 tw-gap-4 md:tw-gap-8 lg:tw-gap-12">
          {currentItems.map((recording) => (
            <li
              className="tw-col-span-12 sm:tw-col-span-6 md:tw-col-span-4"
              key={recording.id}
            >
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artwork={recording.artwork}
                artworkKey={recording.artworkKey}
                artworkMode={recording.artworkMode}
                type={'recording'}
                color={recording.color}
                duration={recording.duration}
              />
            </li>
          ))}
        </ol>
      )}
      {recordingsPageCount > 1 && (
        <ReactPaginate
          containerClassName={classNames(paginationStyles['pagination'], {
            [paginationStyles['pagination--loading']]: loading
          })}
          pageClassName={paginationStyles['pagination__page']}
          pageLinkClassName={paginationStyles['pagination__link']}
          activeLinkClassName={paginationStyles['pagination__link--active']}
          previousLinkClassName={paginationStyles['pagination__link--prev']}
          nextLinkClassName={paginationStyles['pagination__link--next']}
          disabledLinkClassName={paginationStyles['pagination__link--disabled']}
          breakLabel="..."
          nextLabel="next ›"
          forcePage={setInitialPage}
          onPageChange={onPageChange}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 2 : 5}
          pageCount={recordingsPageCount}
          previousLabel="‹ prev"
          renderOnZeroPageCount={() => null}
        />
      )}
    </div>
  );
};
