import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { setChatVisible } from 'features/chat/chatSlice';
import { useCurrentEvent } from 'features/events/eventHooks';
import { IconButton } from 'components/Buttons/IconButton';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useSocialElementsDisabled } from 'features/channel/channelHooks';

interface ChatButtonProps {
  isDisabled?: boolean;
}

export const ChatButton: FunctionComponent<ChatButtonProps> = (props) => {
  const chatVisible = useSelector((state: RootState) => state.chat.isVisible);
  const dispatch = useDispatch();
  const event = useCurrentEvent();
  const hasNewComments = useSelector(
    (state: RootState) => state.chat.hasNewComments
  );
  const { postMessage } = useNativeEvents();
  const socialElementsDisabled = useSocialElementsDisabled();

  const selectIcon = (): string => {
    if (chatVisible) {
      return 'chatClose';
    } else {
      return 'chatOn';
    }
  };
  const label = props.isDisabled ? 'Chat disabled' : 'Chat now';

  useEffect(() => {
    postMessage({
      chatVisible: chatVisible
    });
  }, [chatVisible, postMessage]);

  if (socialElementsDisabled) {
    return null;
  }

  if (event && !event.chatEnabled) {
    return null;
  }

  return (
    <IconButton
      ariaExpanded={chatVisible}
      label={label}
      onClick={() => {
        dispatch(setChatVisible(!chatVisible));
      }}
      isDisabled={props.isDisabled}
      icon={selectIcon()}
      showNotification={!chatVisible && hasNewComments}
    />
  );
};
