import React, { FunctionComponent, useContext } from 'react';
import { RootState } from 'rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoVisible } from 'features/events/eventsSlice';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { EventModel } from 'features/events/EventModel';
import { DateTime } from 'luxon';
import Linkify from 'linkify-react';
import { IconButton } from 'components/Buttons/IconButton';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface EventInfoProps {
  event: EventModel | null;
}

export const EventInfo: FunctionComponent<EventInfoProps> = ({ event }) => {
  const dispatch = useDispatch();
  const showInfo = useSelector((state: RootState) => state.events.showInfo);
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const { isListenerAppWebview } = useContext(UserAgentContext);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener',
    format: {
      url: (value: string) =>
        value.length > 50 ? value.slice(0, 50) + '…' : value
    },
    className: 'comment__url'
  };

  if (!event || !channel || isListenerAppWebview) {
    return null;
  }

  const renderEventStartDate = () => {
    if (event.parsedLocalStartedAt) {
      return (
        <dl>
          <dt>Started at:</dt>
          <dd>
            {event.parsedLocalStartedAt.toLocaleString(DateTime.DATETIME_MED)}
          </dd>
        </dl>
      );
    } else if (event.parsedLocalStartsAt) {
      return (
        <dl>
          <dt>Starts at:</dt>
          <dd>
            {event.parsedLocalStartsAt.toLocaleString(DateTime.DATETIME_MED)}
          </dd>
        </dl>
      );
    } else {
      return null;
    }
  };

  const renderEventEndDate = () => {
    if (event.parsedLocalEndedAt) {
      return (
        <dl>
          <dt>Ended at:</dt>
          <dd>
            {event.parsedLocalEndedAt.toLocaleString(DateTime.DATETIME_MED)}
          </dd>
        </dl>
      );
    } else if (event.parsedLocalEndsAt) {
      return (
        <dl>
          <dt>Ends at:</dt>
          <dd>
            {event.parsedLocalEndsAt.toLocaleString(DateTime.DATETIME_MED)}
          </dd>
        </dl>
      );
    } else {
      return null;
    }
  };

  const openModal = () => {
    dispatch(setInfoVisible(true));
  };

  const closeModal = () => {
    dispatch(setInfoVisible(false));
  };

  return (
    <>
      <IconButton label={'Info'} onClick={openModal} icon={'info'} />
      <ModalWrapper
        isOpen={showInfo}
        title={'Event details'}
        onRequestClose={closeModal}
        size={'wide'}
      >
        <h3>{event.title}</h3>
        <div className="grid">
          <div className="col-xss-12 col-md-4">{renderEventStartDate()}</div>
          <div className="col-xss-12 col-md-4">{renderEventEndDate()}</div>
        </div>
        {event.description && (
          <p className="textarea-format">
            <Linkify options={linkifyOptions}>{event.description}</Linkify>
          </p>
        )}
      </ModalWrapper>
    </>
  );
};
