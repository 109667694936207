import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';
import { Link } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { useIsFullScreenView } from 'features/theme/themeHooks';
import { Visualizer } from 'components/Player/Visualizer';

interface IconButtonLocationState {
  hideLoader?: boolean;
}

interface IconButtonProps {
  label: any;
  type?: 'button' | 'submit';
  link?: boolean;
  external?: boolean;
  href?: string;
  locationState?: IconButtonLocationState;
  icon: string;
  isLight?: boolean;
  isTransparent?: boolean;
  isDisabled?: boolean;
  showLabel?: boolean;
  tooltip?: boolean;
  ariaControls?: string;
  ariaExpanded?: boolean;
  outline?: boolean;
  size?: 'mini' | 'small' | 'large';
  noBackground?: boolean;
  isHighlighted?: boolean;
  isLoading?: boolean;
  isPlaying?: boolean;
  loadingType?: 'fill';
  showNotification?: boolean;
  target?: string;
  rel?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const IconButton: FunctionComponent<IconButtonProps> = (props) => {
  const isFullScreenView = useIsFullScreenView();
  const buttonSize = () => {
    if (props.size === 'mini') {
      return 'tw-h-[24px] tw-w-[24px] tw-basis-[24px] tw-text-lg';
    } else if (props.size === 'small') {
      return 'tw-h-[32px] tw-w-[32px] tw-basis-[32px] tw-text-xl';
    } else if (props.size === 'large') {
      return 'tw-h-[48px] tw-w-[48px] tw-basis-[48px] tw-text-xl';
    } else {
      return 'tw-h-[36px] tw-w-[36px] tw-basis-[36px]';
    }
  };

  const iconSize = () => {
    if (props.size === 'mini') {
      return 12;
    } else if (props.size === 'small') {
      return 16;
    } else {
      return 20;
    }
  };

  const buttonBackground = () => {
    if (props.noBackground) {
      return 'tw-border-0 tw-bg-transparent tw-text-theme-color hover:tw-text-label';
    } else if (props.outline) {
      return 'tw-bg-transparent tw-border tw-border-solid tw-border-line-color tw-text-theme-color hover:tw-text-label hover:tw-border-label';
    } else if (props.isTransparent) {
      return 'tw-border-0 tw-bg-white/10 hover:tw-bg-white/20 tw-text-white hover:tw-text-white tw-backdrop-blur-sm [.mini-player.light-contrast_&]:tw-bg-black/10 [.mini-player.light-contrast_&]:hover:tw-bg-black/20 [.mini-player.light-contrast_&]:tw-text-black';
    } else if (props.isLight) {
      return 'tw-border-0 tw-bg-light-background tw-text-label hover:tw-text-theme-color';
    } else {
      return `tw-border-0 hover:tw-text-white ${
        isFullScreenView
          ? 'tw-bg-white/10 hover:tw-bg-white/20 tw-backdrop-blur-sm tw-text-white [.has-image_&]:tw-bg-theme-color [.wrapper.has-image.light-contrast_&]:tw-text-black [.wrapper.dark-contrast_&]:tw-text-white'
          : 'tw-bg-theme-color tw-text-white [.light-contrast_&]:tw-text-black'
      }`;
    }
  };

  const cssClassNames = () => {
    return classNames(
      'tw-group tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer disabled:tw-cursor-default disabled:opacity-10',
      {
        'tw-w-fit tw-pl-4 tw-pr-6 tw-gap-2 tw-whitespace-nowrap':
          props.showLabel
      },
      buttonSize(),
      buttonBackground(),
      {
        'tw-overflow-hidden before:tw-content-[""] before:tw-absolute before:tw-h-full before:tw-w-full before:tw-left-1/2 before:tw--translate-x-1/2 before:tw-bg-black before:tw-animate-fill':
          props.isLoading && props.loadingType === 'fill'
      },
      {
        'after:tw-content-[""] after:tw-border-[3px] after:tw-border-solid after:tw-border-theme-color after:tw-bg-white after:tw-h-[12px] after:tw-w-[12px] after:tw-rounded-full after:tw-absolute after:tw-top-2 after:tw-right-2 after:tw-z-20 [.light-contrast_&]:after:tw-text-label':
          props.showNotification
      }
    );
  };

  const buttonContent = () => {
    if (props.isLoading && props.loadingType !== 'fill') {
      return <Loader isWhite={true} size={16} />;
    }
    return (
      <>
        {props.isPlaying ? (
          <Visualizer isMini={true} />
        ) : (
          <Icon
            hidden={true}
            icon={props.icon}
            label={props.label}
            height={iconSize()}
            width={iconSize()}
          />
        )}
        <span
          className={classNames({
            'tw-sr-only': !props.showLabel && !props.tooltip,
            'tw-bg-black tw-rounded-[4px] tw-text-white tw-text-base tw-left-full tw--ml-[4px] tw-px-[4px] tw-py-[1px] tw-absolute tw--translate-y-1/2 tw-top-1/2 tw-z-20 tw-opacity-0 tw-invisible group-hover:tw-opacity-100 group-hover:tw-visible before:tw-content-[""] before:tw-h-0 before:tw-w-0 b before:tw-absolute before:tw-top-1/2 before:tw-right-full before:tw--mt-[2px] before:tw-border-t-[2px] before:tw-border-t-transparent before:tw-border-r-[4px] before:tw-border-r-black before:tw-border-b-[2px] before:tw-border-b-transparent before:tw-border-solid':
              props.tooltip
          })}
        >
          {props.label}
        </span>
      </>
    );
  };

  if (props.external && props.href) {
    return (
      <a
        href={props.href}
        className={cssClassNames()}
        {...(props.target && { target: props.target })}
        {...(props.rel && { rel: props.rel })}
      >
        {buttonContent()}
      </a>
    );
  }

  if (props.link && props.href) {
    return (
      <Link
        to={props.href}
        className={cssClassNames()}
        onClick={props.onClick}
        state={props.locationState}
      >
        {buttonContent()}
      </Link>
    );
  }

  return (
    <button
      aria-label={props.label}
      className={cssClassNames()}
      onClick={props.onClick}
      type={props.type ? props.type : 'button'}
      {...(props.ariaControls && { 'aria-controls': props.ariaControls })}
      {...(props.ariaExpanded !== undefined && {
        'aria-expanded': props.ariaExpanded
      })}
      {...(props.isDisabled && { disabled: props.isDisabled })}
    >
      {buttonContent()}
    </button>
  );
};
