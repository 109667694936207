import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useDispatch } from 'react-redux';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { unloadEvent, unloadRecording } from 'thunks/navigationThunks';
import { IconButton } from '../Buttons/IconButton';
import { VolumeControl } from './VolumeControl';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAudio } from 'features/audio/audio';
import { motion, AnimatePresence } from 'framer-motion';
import { Visualizer } from 'components/Player/Visualizer';
import { useReducedMotion } from 'framer-motion';
import { isAnimatingLayout } from 'features/ui/uiSlice';
import { isMobileOnly } from 'react-device-detect';
import { SquareImage } from 'components/Images/SquareImage';
import { fetchAudioAbort } from 'features/audio/audioThunks';
import { setEventMinimised } from 'features/events/eventsSlice';
import tinycolor from 'tinycolor2';
import { Media } from 'api/v3/types/media';

interface MiniPlayerProps {
  title: string;
  url: string;
  artwork: Media;
  meta?: ReactNode;
  showVolumeControl: boolean;
  color: string;
}

export const MiniPlayer: FunctionComponent<MiniPlayerProps> = (props) => {
  const dispatch = useDispatch();
  const { stop } = useAudio();
  const shouldReduceMotion = useReducedMotion();
  const [colorContrastClass, setColorContrastClass] = useState<string>('');

  const miniPlayerVisible = useSelector(
    (state: RootState) => state.channel.miniPlayerVisible
  );

  const onExpand = () => {
    dispatch(setMiniPlayerVisible(false));
    dispatch(setEventMinimised(false));
  };

  const onClose = () => {
    fetchAudioAbort();
    stop(true);
    dispatch(unloadEvent());
    dispatch(unloadRecording());
  };

  const onAnimationStart = () => {
    dispatch(isAnimatingLayout(true));
  };

  const onAnimationComplete = () => {
    dispatch(isAnimatingLayout(false));
  };

  const linkState = {
    prevPath: location.pathname,
    animate: true,
    hideLoader: true
  };

  useEffect(
    function setCardColorContrast() {
      const color = tinycolor(props.color);
      const colorIsLight = color.getLuminance() > 0.5;
      setColorContrastClass(colorIsLight ? 'light-contrast' : 'dark-contrast');
    },
    [props.color, setColorContrastClass]
  );

  return (
    <AnimatePresence>
      {miniPlayerVisible && (
        <motion.div
          className={`mini-player ${colorContrastClass} tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-h-[calc(64px+env(safe-area-inset-bottom,0))] tw-z-50 tw-bg-theme-color hover:tw-text-white [.light-contrast_&]:tw-text-black tw-pr-4 tw-shadow-[0_0_14px_0_rgba(0,0,0,0.3)]`}
          style={{ backgroundColor: props.color }}
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
          transition={{ duration: shouldReduceMotion ? 0 : 0.3 }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <div className="tw-flex tw-items-center tw-pr-4 tw-h-[64px] tw-gap-8">
            <Link
              to={props.url}
              onClick={onExpand}
              className="tw-relative tw-block tw-h-[64px] tw-w-[64px] tw-flex-none"
              state={linkState}
            >
              <SquareImage
                artworkUrl={props.artwork.image.small}
                squareCorners={true}
              />
              <div className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-h-[24px] tw-w-[24px] tw-z-10">
                <Visualizer isMini={true} />
              </div>
            </Link>
            {!isMobileOnly && props.showVolumeControl && (
              <VolumeControl invert={true} />
            )}
            <Link
              to={props.url}
              onClick={onExpand}
              className={classNames(styles['player-mini__details'])}
              state={linkState}
            >
              <div>
                <h2
                  className={classNames(
                    styles['player-mini__title'],
                    'truncate'
                  )}
                >
                  {props.title}
                </h2>
                {props.meta && (
                  <div className={styles['player-mini__meta']}>
                    {props.meta}
                  </div>
                )}
              </div>
            </Link>
            <div className={styles['player__actions']} role="toolbar">
              <IconButton
                icon={'x'}
                label={'Close mini player'}
                showLabel={false}
                onClick={onClose}
                isTransparent={true}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
