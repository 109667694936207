import React, { FunctionComponent, useContext, useState } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { ChannelNetworkList } from './ChannelNetworkList';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';

export const ChannelNetworkModal: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const [modalOpen, toggleModal] = useState<boolean>(false);
  const { isNativeAppWebview } = useContext(UserAgentContext);

  const openModal = () => {
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  if (isNativeAppWebview || !channel?.networkAvailable) {
    return null;
  }

  return (
    <>
      <IconButton
        label={'Show network'}
        onClick={openModal}
        icon={'chevronDown'}
        size="mini"
        noBackground={true}
      />
      <ModalWrapper
        isOpen={modalOpen}
        title={'Network'}
        onRequestClose={closeModal}
        size={'wide'}
      >
        <ChannelNetworkList />
      </ModalWrapper>
    </>
  );
};
