import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { NetworkChannel } from 'features/channel/channelSlice';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';

interface ChannelNetworkListLinkProps {
  children: ReactNode;
  channel: NetworkChannel;
}

export const ChannelNetworkListLink: FunctionComponent<
  ChannelNetworkListLinkProps
> = ({ channel, children }) => {
  const { nativeUrlSchemeLink } = useNativeEvents();
  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (isNativeAppWebview) {
    return (
      <a
        href={nativeUrlSchemeLink('mixlr', 'channel', {
          url: channel.url,
          name: channel.username,
          theme_color: channel.themeColor,
          logo_url: channel.logoUrl
        })}
      >
        {children}
      </a>
    );
  }

  return (
    <a href={channel.url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
